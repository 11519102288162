import { Select } from "@mantine/core";
import { useEffect } from "react";
import useSWR from "swr";
import useEdition from "../../../../../../hooks/useEdition";
import useRace from "../../../../../../hooks/useRace";

const RaceSelect = () => {
  const { edition } = useEdition();
  const race: any = useRace();

  const { data } = useSWR(
    "/events/" + edition.event.id + "/editions/" + edition.id + "/races",
    { suspense: true }
  );

  useEffect(() => {
    race.setRaces(data.races);
  }, [data]);

  const generateSelectData = () => {
    const options = [];
    options.push({ value: "", label: "General" });
    data.races.map((i: any) => {
      options.push({ value: i.id, label: i.name });
    });
    return options;
  };

  const handleRaceChange = (id: any) => {
    race.setActiveRace(id);
  };
  return (
    <Select
      mr={"lg"}
      value={race.race}
      onChange={handleRaceChange}
      placeholder="Selecciona una carrera"
      data={generateSelectData()}
    />
  );
};

export default RaceSelect;
