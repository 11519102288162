import { Button, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import api from "../../../../api";
import { handleResponseError } from "../../../../utils/error";
import { showSuccessToast } from "../../../../utils/toasts";

interface CreateUpdateEventFormProps {
  event?: any;
}

const CreateUpdateEventForm: FC<CreateUpdateEventFormProps> = (
  props: CreateUpdateEventFormProps
) => {
  const { event } = props;

  const generateInitialProps = () => {
    if (event) {
      return {
        name: event.name,
        slug: event.slug,
      };
    } else {
      return {
        name: "",
        slug: "",
      };
    }
  };
  const form = useForm({
    initialValues: generateInitialProps(),
    validate: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const createEvent = (values: any) => {
    api
      .post("/events", values)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Evento creado correctamente" });
          navigate("/events");
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  const updateEvent = (values: any) => {
    api
      .put(`/events/${event.id}`, values)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Evento actualizado correctamente" });
          navigate("/events");
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };
  return (
    <div>
      <form
        onSubmit={form.onSubmit((values) => {
          if (event) {
            updateEvent(values);
          } else {
            createEvent(values);
          }
        })}
      >
        <TextInput
          label="Nombre del evento"
          required
          name="name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Slug del evento"
          required
          mt="sm"
          name="slug"
          {...form.getInputProps("slug")}
        />

        <Button type="submit" fullWidth mt="sm">
          {event ? "Actualizar evento" : "Crear evento"}
        </Button>
      </form>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre del evento es requerido"),
  slug: Yup.string()
    .required("El slug es requerido")
    .matches(
      /^[A-Za-z0-9_-]+$/g,
      "El slug solo puede contener letras, números, guiones y guiones bajos"
    ),
});
export default CreateUpdateEventForm;
