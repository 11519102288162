import SWRrender from "../../core/SWRrender";
import EventLayout from "../../layouts/EventLayout";

interface Props {
  children: React.ReactNode;
  redirectOnError?: string;
  toastMessage?: string;
}
const BaseEditionComponent = (props: Props) => {
  const {
    redirectOnError = "/events",
    toastMessage = "No se ha podido encontrar la edicion indicada",
    children,
  } = props;
  return (
    <SWRrender
      spinner
      redirectOnError={redirectOnError && redirectOnError}
      toastMessage={toastMessage && toastMessage}
    >
      <EventLayout isEvent={false}>{children}</EventLayout>
    </SWRrender>
  );
};

export default BaseEditionComponent;
