import { Button, FileInput, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { FC } from "react";
import * as Yup from "yup";
import { DatePicker } from "@mantine/dates";
import { IconUpload } from "@tabler/icons";
import api from "../../../../api";
import { showSuccessToast } from "../../../../utils/toasts";
import { handleResponseError } from "../../../../utils/error";
import useEventEditions from "../../../../hooks/useEventEditions";
import SelectManager from "../SelectManager";

interface Props {
  edition?: any;
}

const CreateUpdateEditionForm: FC<Props> = (props: Props) => {
  const { edition } = props;

  const { eventId } = useEventEditions();

  const generateInitialProps = () => {
    if (edition) {
      return {
        name: edition.name,
        year: edition.year,
        location: edition.location,
        start_date: edition.start_date,
        manager_id: edition.manager_id,
        poster: edition.poster,
      };
    } else {
      return {
        name: "",
        year: "",
        location: "",
        start_date: new Date(),
        manager_id: "",
        poster: "",
      };
    }
  };

  const form = useForm({
    initialValues: generateInitialProps(),
    validate: yupResolver(validationSchema),
  });

  const createEdition = (values: any) => {
    var formData = new FormData();
    formData.append("poster", values.poster);
    api
      .post(
        `/events/${eventId}/editions`,
        { ...values, ...formData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.ok) {
          showSuccessToast({
            message: "Edición creada correctamente",
          });
          //navigate("/event-managers");
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  return (
    <div>
      <form
        onSubmit={form.onSubmit((values) => {
          console.log(values);
          if (edition) {
          } else {
            createEdition(values);
          }
        })}
      >
        <TextInput
          label="Nombre de la edición"
          required
          name="name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Año de la edición"
          required
          mt="sm"
          type={"number"}
          name="year"
          {...form.getInputProps("year")}
        />
        <TextInput
          label="Lugar de la edición"
          required
          mt="sm"
          name="location"
          {...form.getInputProps("location")}
        />

        <DatePicker
          label="Fecha del evento"
          required
          mt="sm"
          name="start_date"
          {...form.getInputProps("start_date")}
        />
        <SelectManager form={form} inputName="manager_id" />

        <FileInput
          label="Cartel de la edición"
          mt="sm"
          name="poster"
          placeholder="Selecciona el cartel de la edición"
          accept="image/png"
          type="button"
          icon={<IconUpload size={14} />}
          {...form.getInputProps("poster")}
        />

        <Button type="submit" fullWidth mt="sm">
          {edition ? "Actualizar evento" : "Crear evento"}
        </Button>
      </form>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre del evento es requerido"),
  year: Yup.string().required("El año del evento es requerido"),
  location: Yup.string().required("El lugar del evento es requerido"),
  start_date: Yup.date().required("La fecha del evento es requerida"),
  manager_id: Yup.string().required("El manager del evento es requerido"),
});
export default CreateUpdateEditionForm;
