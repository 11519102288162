import { Badge, Button, Card, Group, Text, TextInput } from "@mantine/core";
import { IconPlus, IconSearch } from "@tabler/icons";
import { useState } from "react";
import useSWR from "swr";
import BaseEditionComponent from "../../../core/BaseEditionComponent";
import SWRrender from "../../../core/SWRrender";
import useEdition from "../../../hooks/useEdition";
import useRace from "../../../hooks/useRace";
import useUser from "../../../hooks/useUser";
import { fetcherWithQuery } from "../../../utils/fetchers";
import { handleFunctionPermissions } from "../../../utils/permissions";
import ClubsTable from "./components/ClubsTable";
import styles from "./styles";

const EditionClubs = () => {
  return (
    <BaseEditionComponent>
      <SWRrender>
        <ListClubs />
      </SWRrender>
    </BaseEditionComponent>
  );
};

const ListClubs = () => {
  const { classes } = styles();
  const race: any = useRace();
  const {
    user: { permissions },
  } = useUser();
  const { edition } = useEdition();

  const [search, setSearch] = useState("");
  const {
    data: { clubs },
  } = useSWR(
    [
      `/events/${edition.event.id}/editions/${edition.id}/clubs`,
      `${race.race ? "race=" + race.race : ""}`,
    ],
    { suspense: true, fetcher: fetcherWithQuery }
  );

  return (
    <Card>
      <Card.Section
        p="md"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Text weight={500} size="md">
          Listado de clubes inscritos
        </Text>
      </Card.Section>
      {race.race != "" && (
        <Group>
          <Badge>
            {" "}
            Carrera: <b>{race.currentRace.name}</b>
          </Badge>
        </Group>
      )}

      <div className={classes.header}>
        {handleFunctionPermissions(["timing.config"], permissions) ? (
          <div>
            <Button>
              <IconPlus />
            </Button>
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <TextInput
            width={"100%"}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Buscar clubes"
            icon={<IconSearch />}
          />
        </div>
      </div>

      <ClubsTable data={clubs} search={search} />
    </Card>
  );
};

export default EditionClubs;
