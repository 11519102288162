import { Avatar, Menu, useMantineColorScheme } from "@mantine/core";
import { mutate } from "swr";
import { Logout, MoonStars, Sun } from "tabler-icons-react";
import api from "../../../../../../api";
import useUser from "../../../../../../hooks/useUser";
import { deleteToken } from "../../../../../../utils/token";

const ProfileMenu = ({ classes }: any) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const {
    user: { name },
  } = useUser();

  const logOut = () => {
    api.post("/logout").then(() => {
      deleteToken();
      mutate("/user");
    });
  };
  // TODO: check mantine fix menu first open
  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <Avatar color="cyan" radius="xl" mr="sm" className={classes.avatar}>
          {name.charAt(0)}
        </Avatar>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          icon={
            colorScheme === "dark" ? <Sun size={14} /> : <MoonStars size={14} />
          }
          onClick={() => toggleColorScheme()}
        >
          {colorScheme === "dark" ? "Tema claro" : "Tema oscuro"}
        </Menu.Item>
        <Menu.Item
          color="red"
          icon={<Logout size={14} />}
          onClick={() => logOut()}
        >
          Cerrar sesión
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ProfileMenu;
