import { Modal, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCheck, IconX } from "@tabler/icons";
import { mutate } from "swr";
import api from "../../../../../api";
import useEdition from "../../../../../hooks/useEdition";
import useRace from "../../../../../hooks/useRace";
import { handleResponseError } from "../../../../../utils/error";
import {
  showLoadingToast,
  updateLoadingToast,
} from "../../../../../utils/toasts";

function GenerateChipsModal(props: any) {
  const { opened, setOpened } = props;
  const { edition } = useEdition();
  const race: any = useRace();

  const form = useForm({
    initialValues: {
      from: "",
      to: "",
      group: "0",
    },
  });

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Generar chips"
      >
        <form
          onSubmit={form.onSubmit((values) => {
            showLoadingToast({
              message: "Generando chips",
              id: "generate-chips",
            });
            setOpened(false);
            api
              .post(
                `/events/${edition.event.id}/editions/${edition.id}/chips/generate`,
                values
              )
              .then((res) => {
                if (res.data.ok) {
                  updateLoadingToast({
                    id: "generate-chips",
                    title: "Generando chips",
                    message: "Chips generados correctamente",
                    icon: <IconCheck size={16} />,
                    color: "teal",
                  });
                  mutate(
                    `/events/${edition.event.id}/editions/${edition.id}/chips?${
                      race.race ? "race=" + race.race : ""
                    }`
                  );
                  form.reset();
                }
              })
              .catch((err) => {
                handleResponseError(err);
                updateLoadingToast({
                  id: "generate-chips",
                  title: "Generando chips",
                  message: "Error al generar chips",
                  icon: <IconX size={16} />,
                  color: "red",
                });
              });
          })}
        >
          <TextInput
            label="Desde el dorsal"
            name="from"
            {...form.getInputProps("from")}
          />
          <TextInput
            label="Hasta el dorsal"
            name="to"
            mt="sm"
            {...form.getInputProps("to")}
          />
          <TextInput
            label="Grupo"
            name="group"
            mt="sm"
            {...form.getInputProps("group")}
          />

          <Button type="submit" fullWidth mt="sm">
            Generar chips
          </Button>
        </form>
      </Modal>
    </>
  );
}

export default GenerateChipsModal;
