import { Center, Image, Text } from "@mantine/core";
import ForbiddenfIllustration from "../../assets/error/403.png";

const Forbidden = () => {
  return (
    <div>
      <Center
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Image src={ForbiddenfIllustration} />
        <Text color={"#407bff"} size="xl" weight={"bolder"}>
          No tienes permisos
        </Text>
        <Text color={"#407bff"} size="md">
          No tienes permisos para acceder a este recurso. Si piensas que es un
          error, contacta con el administrador de tu empresa.
        </Text>
      </Center>
    </div>
  );
};

export default Forbidden;
