import dayjs from "dayjs";
import BaseTable from "../../../../../core/BaseTable";

const columns = [
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Corredores",
    accessor: "start_age",
  },
];

export function ClubsTable(props: any) {
  return <BaseTable columns={columns} {...props} />;
}

export default ClubsTable;
