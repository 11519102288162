import useSWR from "swr";
import { fetcherWithQuery } from "../utils/fetchers";
import { setCompany } from "../utils/storeCompany";

const useCompany = () => {
  const domain = window.location.hostname;

  const { data, error } = useSWR(`/company`);

  if (data && error?.status === 404) {
    return {
      company: null,
      error: {
        message: "Company not found",
      },
      isLoading: false,
    };
  }

  // Save company data in localStorage
  setCompany(data);

  return { company: data?.company, error, isLoading: !data && !error };
};

export default useCompany;
