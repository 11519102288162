import { Alert, Badge, Button, Card, Group, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { AlertCircle, Settings } from "tabler-icons-react";
import BaseEditionComponent from "../../../core/BaseEditionComponent";
import SWRrender from "../../../core/SWRrender";
import useEdition from "../../../hooks/useEdition";
import useRace from "../../../hooks/useRace";
import useUser from "../../../hooks/useUser";
import { handleFunctionPermissions } from "../../../utils/permissions";
import CategoriesConfig from "./components/CategoriesConfig";
import CategoriesModal from "./components/CategoriesModal";
import CategoriesTable from "./components/CategoriesTable";

const EditionCategories = () => {
  const race: any = useRace();
  return (
    <BaseEditionComponent>
      {race.race === "" ? (
        <Alert icon={<AlertCircle size={16} />} color={"yellow"}>
          {" "}
          Selecciona una carrera para ver sus categorias
        </Alert>
      ) : (
        <SWRrender>
          <ListCategorias />
        </SWRrender>
      )}
    </BaseEditionComponent>
  );
};

const ListCategorias = () => {
  const race: any = useRace();

  const [showConfig, setShowConfig] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modal, setModal] = useState(false);

  const { edition } = useEdition();
  const {
    user: { permissions },
  } = useUser();
  const { data } = useSWR(
    `/events/${edition.event.id}/editions/${edition.id}/races/${race.race}/categories`,
    { suspense: true }
  );
  const categories = data?.categories;

  const clickCategory = (e: any) => {
    setSelectedCategory(e);
    setModal(true);
  };

  useEffect(() => {
    if (!modal) {
      setSelectedCategory(null);
    }
  }, [modal]);
  return (
    <>
      <Card>
        <Card.Section
          p="md"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <Text weight={500} size="md">
            Listado de categorias
          </Text>
          <Group position="right" mb="sm">
            <Button
              variant="outline"
              size={"xs"}
              onClick={() => setShowConfig(true)}
            >
              <Settings size={18} />
            </Button>
          </Group>
        </Card.Section>
        {race.race != "" && (
          <Group>
            <Badge>
              {" "}
              Carrera: <b>{race.currentRace.name}</b>
            </Badge>
            <Badge color={"green"}>
              {" "}
              Calculo categorias:{" "}
              <b>
                {race.currentRace.categories_calculation === "current_year"
                  ? "Año actual"
                  : "Dia del evento"}
              </b>
            </Badge>
          </Group>
        )}
        {handleFunctionPermissions(["timing.config"], permissions) && (
          <Card.Section p="md">
            <div>
              <Button onClick={() => setModal(true)}>Nueva categoria</Button>
            </div>
          </Card.Section>
        )}

        <CategoriesTable
          data={categories}
          onClick={(e: any) => clickCategory(e)}
        />
        <CategoriesConfig opened={showConfig} setOpened={setShowConfig} />
        <CategoriesModal
          opened={modal}
          setOpened={setModal}
          selectedCategory={selectedCategory}
        />
      </Card>
    </>
  );
};

export default EditionCategories;
