import { FC, useState } from "react";
import { AppShell, Box } from "@mantine/core";

import EventSidebar from "../components/EventLayout/EventSidebar";
import EditionSidebar from "../components/EventLayout/EditionSidebar";

import styles from "./styles";
import EventHeader from "../components/EventLayout/EventHeader";
interface Props {
  children: React.ReactNode;
  isEvent?: boolean;
}
const Entry: FC<Props> = (props: Props) => {
  const { isEvent = false, children } = props;
  const [open, setOpen] = useState(true);
  const { classes } = styles();
  return (
    <AppShell
      header={<EventHeader open={open} setOpen={setOpen} isEvent={isEvent} />}
      navbar={
        <>
          {isEvent ? (
            <EventSidebar open={open} />
          ) : (
            <EditionSidebar open={open} />
          )}
        </>
      }
      fixed
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[1],
        },
      })}
    >
      <Box className={classes.main}>{children}</Box>
    </AppShell>
  );
};

export default Entry;
