import BaseEditionComponent from "../../../core/BaseEditionComponent";
import useRace from "../../../hooks/useRace";
import ManageRaces from "./components/ManageRaces";

const EditionTimingPage = () => {
  const race: any = useRace();

  return (
    <BaseEditionComponent>
      {race.race === "" && <ManageRaces />}
    </BaseEditionComponent>
  );
};

export default EditionTimingPage;
