import { Card, Image, Text } from "@mantine/core";
import { IconLocation } from "@tabler/icons";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles";

interface EventCardProps {
  event: any;
}
const EventCard: FC<EventCardProps> = (props: EventCardProps) => {
  const { classes } = styles();
  const { event } = props;
  const navigate = useNavigate();

  return (
    <Card
      shadow={"sm"}
      mb="sm"
      style={{ cursor: "pointer" }}
      onClick={() => navigate("/events/" + event.id)}
    >
      <div className={classes.container}>
        <img
          width={"60%"}
          src={
            event?.lastEdition?.poster
              ? event?.lastEdition?.poster
              : "https://www.tiempoabatir.com/assets/img/no-poster.jpg"
          }
          className={classes.poster}
        />

        <div className={classes.eventInfo}>
          <Text weight={"bold"}>{event.name}</Text>

          <Text>
            {event?.lastEdition?.location ? (
              <>
                <IconLocation size={10} /> {event?.lastEdition?.location}
              </>
            ) : (
              ""
            )}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default EventCard;
