import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  image: {
    width: "30%",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      width: "60%",
    },
  },
}));

export default useStyles;
