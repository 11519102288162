import { showNotification, updateNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";

export const showErrorToast = (args) => {
  showNotification({
    ...args,
    color: "red",
    icon: <X />,
  });
};

export const showSuccessToast = (args) => {
  showNotification({
    ...args,
    color: "green",
    icon: <Check />,
  });
};

export const showLoadingToast = (args) => {
  showNotification({
    loading: true,
    ...args,
    autoClose: false,
    disallowClose: true,
  });
};

export const updateLoadingToast = (args) => {
  updateNotification({
    ...args,
    autoClose: 2000,
  });
};
