import { Card, Text } from "@mantine/core";
import MainLayout from "../../../layouts/MainLayout";
import CreateUpdateEventForm from "../components/CreateUpdateEventForm";

const CreateEvent = () => {
  return (
    <MainLayout>
      <Card>
        <Card.Section p="md">
          <Text weight={500} size="md">
            Crear evento
          </Text>
        </Card.Section>

        <Card.Section p="md">
          <CreateUpdateEventForm />
        </Card.Section>
      </Card>
    </MainLayout>
  );
};

export default CreateEvent;
