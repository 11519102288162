import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { SWRConfig } from "swr";
import CheckCompany from "./CheckCompany";
import { fetcher } from "./utils/fetchers";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { NotificationsProvider } from "@mantine/notifications";
import { RaceProvider } from "./context/RaceContext";
import dayjs from "dayjs";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <SWRConfig
    value={{
      fetcher: fetcher,
    }}
  >
    <RaceProvider>
      <EntryPointMantine>
        <CheckCompany>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </CheckCompany>
      </EntryPointMantine>
    </RaceProvider>
  </SWRConfig>
);

function EntryPointMantine(props: any) {
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme, primaryColor: "cyan" }}
        withGlobalStyles
      >
        <NotificationsProvider>{props.children}</NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
