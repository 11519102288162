import { Button, Modal, Select, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { FC, useEffect } from "react";
import { mutate } from "swr";
import api from "../../../../../api";
import useEdition from "../../../../../hooks/useEdition";
import useRace from "../../../../../hooks/useRace";
import { handleResponseError } from "../../../../../utils/error";
import { showSuccessToast } from "../../../../../utils/toasts";

interface Props {
  opened: boolean;
  selectedCategory?: any;
  setOpened: (opened: boolean) => void;
}

const CategoriesModal: FC<Props> = (props: Props) => {
  const { opened, setOpened, selectedCategory } = props;
  const category = selectedCategory;
  const race: any = useRace();
  const { edition } = useEdition();

  const form = useForm({
    initialValues: {
      name: "",
      start: new Date(),
      start_age: "",
      end: new Date(),
      end_age: "",
      gender: "",
    },
  });
  const calculateDateStart = (age: any) => {
    const now = new Date();
    if (race.currentRace.categories_calculation == "current_year") {
      const start = new Date("01/01/" + now.getFullYear());
      start.setFullYear(start.getFullYear() - age);
      form.setFieldValue("start", start);
    } else {
      const start = new Date(race.currentRace.started_at);
      start.setFullYear(start.getFullYear() - age);
      form.setFieldValue("start", start);
    }
  };
  const calculateDateEnd = (age: any) => {
    const now = new Date();
    if (race.currentRace.categories_calculation == "current_year") {
      let end = new Date("01/01/" + now.getFullYear());
      end.setFullYear(end.getFullYear() - age);
      end = new Date(end.getFullYear(), 11, 31);
      form.setFieldValue("end", end);
    } else {
      const end = new Date(race.currentRace.started_at);
      end.setFullYear(end.getFullYear() - age);
      form.setFieldValue("end", end);
    }
  };

  useEffect(() => {
    calculateDateEnd(form.values.end_age);
    calculateDateStart(form.values.start_age);
  }, [form.values.end_age, form.values.start_age]);

  const create = (values: any) => {
    api
      .post(
        `/events/${edition.event.id}/editions/${edition.id}/races/${race.race}/categories`,
        {
          ...values,
          end: values.end.toDateString(),
          start: values.start.toDateString(),
        }
      )
      .then((data) => {
        if (data.data.ok) {
          showSuccessToast({
            message: "Categoria creada correctamente",
          });
          mutate(
            `/events/${edition.event.id}/editions/${edition.id}/races/${race.race}/categories`
          );
          setOpened(false);
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  const update = (values: any) => {
    api
      .put(
        `/events/${edition.event.id}/editions/${edition.id}/races/${race.race}/categories/${category.id}`,
        {
          ...values,
          end: values.end.toDateString(),
          start: values.start.toDateString(),
        }
      )
      .then((data) => {
        if (data.data.ok) {
          showSuccessToast({
            message: "Categoria actualizada correctamente",
          });
          mutate(
            `/events/${edition.event.id}/editions/${edition.id}/races/${race.race}/categories`
          );
          setOpened(false);
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  useEffect(() => {
    if (!opened) {
      form.reset();
    }

    if (opened && category) {
      form.setValues({ ...category, gender: String(category.gender) });
    }
  }, [opened]);

  console.log(form.values);
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title={category ? "Actualizar categoría" : "Nueva categoría"}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          if (category) {
            update(values);
          } else {
            create(values);
          }
        })}
      >
        <TextInput
          label="Nombre de la categoría"
          name="name"
          required
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Desde año"
          name="start_age"
          required
          mt={"sm"}
          {...form.getInputProps("start_age")}
        />
        <DatePicker
          label="Desde fecha"
          name="start"
          mt={"sm"}
          locale="es"
          inputFormat="DD/MM/YYYY"
          required
          {...form.getInputProps("start")}
        />
        <TextInput
          label="Hasta año"
          name="end_age"
          required
          mt={"sm"}
          {...form.getInputProps("end_age")}
        />
        <DatePicker
          label="Hasta fecha"
          name="end"
          inputFormat="DD/MM/YYYY"
          mt={"sm"}
          locale="es"
          required
          {...form.getInputProps("end")}
        />
        <Select
          label="Género"
          name="gender"
          mt={"sm"}
          required
          placeholder="Seleccione un género"
          data={[
            { value: "0", label: "Masculino" },
            { value: "1", label: "Femenino" },
          ]}
          {...form.getInputProps("gender")}
        />
        <Button type="submit" fullWidth mt="sm">
          {category ? "Actualizar categoría" : "Crear categoría"}
        </Button>
      </form>
    </Modal>
  );
};

export default CategoriesModal;
