import { Button, Card, Text, TextInput } from "@mantine/core";
import { IconPlus, IconSearch } from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import SWRrender from "../../core/SWRrender";
import useUser from "../../hooks/useUser";
import MainLayout from "../../layouts/MainLayout";
import { handleFunctionPermissions } from "../../utils/permissions";
import EventsManagersTable from "./components/EventsManagersTable";
import styles from "./styles";

const EventManagers = () => {
  const {
    user: { permissions },
  } = useUser();
  return (
    <MainLayout>
      <Card>
        <Card.Section p="md">
          <Text weight={500} size="md">
            Listado de organizadores
          </Text>
        </Card.Section>

        <SWRrender>
          <RenderEventManagers permissions={permissions} />
        </SWRrender>
      </Card>
    </MainLayout>
  );
};

const RenderEventManagers = ({ permissions }: any) => {
  const {
    data: { eventManagers },
  } = useSWR("/event-managers", { suspense: true });

  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const { classes } = styles();
  return (
    <>
      <Card.Section p="md">
        <div className={classes.header}>
          <div>
            {handleFunctionPermissions(
              ["event-managers.create"],
              permissions
            ) && (
              <Button onClick={() => navigate("/event-managers/create")}>
                <IconPlus />
              </Button>
            )}
          </div>

          <div>
            <TextInput
              width={"100%"}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar organizador"
              icon={<IconSearch />}
            />
          </div>
        </div>
      </Card.Section>

      <Card.Section p="md">
        <EventsManagersTable
          data={eventManagers}
          search={search}
          redirectRoute="/event-managers/<id>"
        />
      </Card.Section>
    </>
  );
};

export default EventManagers;
