import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Forbidden from "./core/Forbidden";
import LoadingCompany from "./core/LoadingCompany";
import useUser from "./hooks/useUser";
import LoginPage from "./pages/Auth/LoginPage";
import mainRoutes from "./routes/mainLayout";

const ProtectedRoute = ({
  user,
  redirectPath = "/auth/login",
  permissions = [],
}: any) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  const checkPermissions = [...permissions, "superadmin", "admin"];
  const wilcard = permissions.map(
    (permission: any) => permission.split(".")[0] + ".*"
  );
  const finalPermissions = [...checkPermissions, ...wilcard];

  if (
    !finalPermissions.some((permission: any) =>
      user.permissions.includes(permission)
    )
  ) {
    return <Forbidden />;
  }

  return <Outlet />;
};

const Router = () => {
  const { user, isLoading } = useUser();

  if (!isLoading) {
    const mainLayoutRoutes = mainRoutes.map((route) => (
      <Route
        element={<ProtectedRoute user={user} permissions={route.permissions} />}
        key={route.route}
      >
        <Route path={route.route} element={<route.component />} />
      </Route>
    ));

    console.log(mainLayoutRoutes);

    return (
      <Routes>
        <>{mainLayoutRoutes}</>
        <Route path="/auth/login" element={<LoginPage />} />
      </Routes>
    );
  }

  return <LoadingCompany />;
};

export default Router;
