import { createContext, useEffect, useMemo, useState } from "react";

export const RaceContext = createContext({});

export const RaceProvider = ({ children }: any) => {
  const [race, setRace] = useState<any>("");
  const [allRace, setAllRace] = useState<any>([]);
  const [currentRace, setCurrentRace] = useState<any>(null);

  const setActiveRace = (id: number) => {
    const result = allRace.filter((i: any) => i.id === id)[0];
    setRace(id);
    setCurrentRace(result);
  };

  const resetRace = () => {
    setRace(null);
  };

  const setRaces = (races: any) => {
    setAllRace(races);
  };

  const raceData = useMemo(
    () => ({
      race,
      setActiveRace,
      resetRace,
      setRaces,
      currentRace,
    }),
    [race, allRace, currentRace]
  );

  useEffect(() => {
    if (race) {
      const result = allRace.filter((i: any) => i.id === race)[0];
      setCurrentRace(result);
    }
  }, [allRace]);
  return (
    <RaceContext.Provider value={raceData}>{children}</RaceContext.Provider>
  );
};
