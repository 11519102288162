import { useState } from "react";
import { AppShell, Box } from "@mantine/core";

import MainHeader from "../components/MainLayout/MainHeader";
import MainSidebar from "../components/MainLayout/MainSidebar";
import styles from "./styles";

interface Props {
  children: React.ReactNode;
}
function MainLayout(props: Props) {
  const [open, setOpen] = useState(true);
  const { classes } = styles();
  return (
    <AppShell
      header={<MainHeader setOpen={setOpen} open={open} />}
      navbar={<MainSidebar open={open} />}
      fixed
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[1],
        },
      })}
    >
      {/*TODO: Content height */}
      <Box className={classes.main}>{props.children}</Box>
    </AppShell>
  );
}

export default MainLayout;
