import { Button, Grid, Modal, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { IconTrash } from "@tabler/icons";
import { useEffect } from "react";
import { mutate } from "swr";
import * as Yup from "yup";
import api from "../../../../../api";
import useEdition from "../../../../../hooks/useEdition";
import useRace from "../../../../../hooks/useRace";
import { handleResponseError } from "../../../../../utils/error";
import { showSuccessToast } from "../../../../../utils/toasts";

function ChipsModal(props: any) {
  const { opened, setOpened, selectedChip, setSelectedChip } = props;

  const { edition }: any = useEdition();
  const race: any = useRace();

  const form = useForm({
    initialValues: {
      number: "",
      chip: "",
    },
    validate: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!opened) {
      setSelectedChip(null);
    } else {
      if (selectedChip) {
        console.log(selectedChip);
        form.setValues(selectedChip);
      }
    }
  }, [opened]);

  const deleteChip = () => {
    if (selectedChip) {
      api
        .delete(
          `/events/${edition.event.id}/editions/${edition.id}/chips/${selectedChip.id}`
        )
        .then((res) => {
          if (res.data.ok) {
            showSuccessToast({
              message: "Chip eliminado correctamente",
            });
            mutate(
              `/events/${edition.event.id}/editions/${edition.id}/chips?` +
                `${race.race ? "race=" + race.race : ""}`
            );
            setOpened(false);
            form.reset();
          }
        })
        .catch((err) => {
          handleResponseError(err);
        });
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Actualizar chip"
      >
        <form
          onSubmit={form.onSubmit((values) => {
            api
              .put(
                `/events/${edition.event.id}/editions/${edition.id}/chips/${selectedChip.id}`,
                values
              )
              .then((data) => {
                if (data.data.ok) {
                  showSuccessToast({
                    message: "Chip actualizado correctamente",
                  });
                  mutate(
                    `/events/${edition.event.id}/editions/${edition.id}/chips?` +
                      `${race.race ? "race=" + race.race : ""}`
                  );
                  setOpened(false);
                  form.reset();
                }
              })
              .catch((err) => {
                handleResponseError(err);
              });
          })}
        >
          <TextInput
            label="Dorsal"
            placeholder="Dorsal"
            name="number"
            {...form.getInputProps("number")}
          />
          <TextInput
            label="Código de chip"
            placeholder="Código de chip"
            name="chip"
            mt="sm"
            {...form.getInputProps("chip")}
          />
          <Grid>
            <Grid.Col span={10}>
              <Button type="submit" fullWidth mt="sm">
                Actualizar chip
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                fullWidth
                mt="sm"
                color={"red"}
                onClick={() => deleteChip()}
              >
                <IconTrash />
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Modal>
    </>
  );
}

const validationSchema = Yup.object().shape({
  number: Yup.string().required("Dorsal es requerido"),
  chip: Yup.string().required("Código de chip es requerido"),
});

export default ChipsModal;
