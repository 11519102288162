import CompanyNotFound from "./core/CompanyNotFound";
import LoadingCompany from "./core/LoadingCompany";
import useCompany from "./hooks/useCompany";

interface CheckCompanyProps {
  children: React.ReactNode;
}

const CheckCompany: React.FC<CheckCompanyProps> = (
  props: CheckCompanyProps
) => {
  const { company, error, isLoading } = useCompany();

  if (isLoading) {
    return <LoadingCompany />;
  }

  if (error && !company) {
    return <CompanyNotFound />;
  }

  if (!isLoading && !error && company) {
    return <>{props.children}</>;
  }
  return <></>;
};

export default CheckCompany;
