import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  leftSection: {
    display: "flex",
    alignItems: "center",
  },

  openMenu: {
    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
      display: "none",
    },
  },

  logo: {
    width: "15%",
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      display: "none",
    },
  },

  avatar: {
    cursor: "pointer",
  },

  rightSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default useStyles;
