import { Badge, Tabs } from "@mantine/core";
import { CalendarStats, List } from "tabler-icons-react";
import SWRrender from "../../core/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import AllEvents from "./Tabs/AllEvents";
import NextEvents from "./Tabs/NextEvents";

const EventsListPage = () => {
  return (
    <MainLayout>
      <Tabs defaultValue={"next_events"}>
        <Tabs.List grow>
          <Tabs.Tab value="next_events" icon={<CalendarStats size={14} />}>
            <>
              Próximos eventos <Badge>10</Badge>
            </>
          </Tabs.Tab>
          <Tabs.Tab value="events" icon={<List size={14} />}>
            Todos los eventos
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="next_events" pt="xs">
          <NextEvents />
        </Tabs.Panel>

        <Tabs.Panel value="events" pt="xs">
          <SWRrender>
            <AllEvents />
          </SWRrender>
        </Tabs.Panel>
      </Tabs>
    </MainLayout>
  );
};

export default EventsListPage;
