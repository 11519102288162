import { Card, Text } from "@mantine/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import useSWRInmutable from "swr/immutable";
import SWRrender from "../../../core/SWRrender";
import EventLayout from "../../../layouts/EventLayout";
import MainLayout from "../../../layouts/MainLayout";
import CreateUpdateEventForm from "../components/CreateUpdateEventForm";

const UpdateEvent = () => {
  const { id } = useParams();
  return (
    <EventLayout isEvent={true}>
      <Card>
        <Card.Section p="md">
          <Text weight={500} size="md">
            Actualizar evento
          </Text>
        </Card.Section>

        <Card.Section p="md">
          <SWRrender>
            <RenderUpdateForm id={id} />
          </SWRrender>
        </Card.Section>
      </Card>
    </EventLayout>
  );
};

const RenderUpdateForm = ({ id }: any) => {
  const {
    data: { event },
  } = useSWRInmutable(`/events/${id}`, { suspense: true });

  useEffect(() => {
    mutate(`/events/${id}`);
  }, []);
  return <CreateUpdateEventForm event={event} />;
};

export default UpdateEvent;
