import { Badge, Button, Card, Text, TextInput } from "@mantine/core";
import { IconPlus, IconSearch } from "@tabler/icons";
import { useState } from "react";
import BaseEditionComponent from "../../../core/BaseEditionComponent";
import SWRrender from "../../../core/SWRrender";
import useRace from "../../../hooks/useRace";
import RunnersTable from "./components/RunnersTable";
import styles from "./styles";

const EditionRunners = () => {
  const race: any = useRace();

  return (
    <BaseEditionComponent>
      <Card>
        <Card.Section p="md">
          <Text weight={500} size="md">
            Listado de corredores
          </Text>
        </Card.Section>
        {race.race != "" && (
          <Badge>
            {" "}
            Carrera: <b>{race.currentRace.name}</b>
          </Badge>
        )}
        <SWRrender>
          <ListRunners />
        </SWRrender>
      </Card>
    </BaseEditionComponent>
  );
};

const ListRunners = () => {
  const { classes } = styles();
  const [search, setSearch] = useState("");
  return (
    <>
      <Card.Section p="md">
        <div className={classes.header}>
          <div>
            <Button>
              <IconPlus />
            </Button>
          </div>

          <div>
            <TextInput
              width={"100%"}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar corredores"
              icon={<IconSearch />}
            />
          </div>
        </div>
      </Card.Section>

      <RunnersTable
        search={search}
        data={[
          {
            number: 2,
            name: "MORENO VALERO, JUAN",
            club: "LEMON TEAM",
            category: "M-MASTER50",
            document: "52758184W",
          },
        ]}
      />
    </>
  );
};

export default EditionRunners;
