import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
  },
  input: {
    width: "100%",
  },

  eventContainer: {
    marginTop: theme.spacing.sm,
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing.sm,
  },
}));

export default useStyles;
