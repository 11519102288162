import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  poster: {
    width: "60px",
    height: "auto",
  },
  eventInfo: {
    marginLeft: theme.spacing.sm,
  },
}));

export default useStyles;
