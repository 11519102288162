import useSWR from "swr";
import { showErrorToast } from "../utils/toasts";

const useEdition = () => {
  const location = window.location.pathname;

  const { data, error } = useSWR(
    `/events/${location.split("/")[2]}/editions/${location.split("/")[3]}`,
    {
      suspense: true,
    }
  );

  if (data && error?.status === 404) {
    return {
      edition: null,
      error: {
        message: "Edition not found",
      },
      isLoading: false,
    };
  }

  return { edition: data?.edition, error, isLoading: !data && !error };
};

export default useEdition;
