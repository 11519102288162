import dayjs from "dayjs";
import BaseTable from "../../../../../core/BaseTable";

const columns = [
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "De edad",
    accessor: "start_age",
  },
  {
    Header: "Desde",
    accessor: "start",
    Cell: ({ value }: any) => <>{dayjs(value).format("DD/MM/YYYY")}</>,
  },
  {
    Header: "Hasta edad",
    accessor: "end_age",
  },
  {
    Header: "Hasta",
    accessor: "end",
    Cell: ({ value }: any) => <>{dayjs(value).format("DD/MM/YYYY")}</>,
  },
  {
    Header: "Género",
    accessor: "gender",
    Cell: ({ value }: any) => <>{!value ? "Masculino" : "Femenino"}</>,
  },
];

export function CategoriesTable(props: any) {
  return <BaseTable columns={columns} {...props} />;
}

export default CategoriesTable;
