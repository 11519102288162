import {
  Badge,
  Button,
  Card,
  Group,
  Menu,
  Text,
  TextInput,
} from "@mantine/core";
import { IconSearch, IconSettings } from "@tabler/icons";
import { useState } from "react";
import useSWR from "swr";
import { DatabaseImport, Settings } from "tabler-icons-react";
import BaseEditionComponent from "../../../core/BaseEditionComponent";
import SWRrender from "../../../core/SWRrender";
import useEdition from "../../../hooks/useEdition";
import useRace from "../../../hooks/useRace";
import useUser from "../../../hooks/useUser";
import { handleFunctionPermissions } from "../../../utils/permissions";
import ChipsModal from "./components/ChipsModal";
import ChipsTable from "./components/ChipsTable";
import GenerateChipsModal from "./components/GenerateChipsModal";
import NewChipButton from "./components/NewChipButton";
import styles from "./styles";

const EditionChips = () => {
  return (
    <BaseEditionComponent>
      <SWRrender>
        <ListChips />
      </SWRrender>
    </BaseEditionComponent>
  );
};

const ListChips = () => {
  const { classes } = styles();
  const race: any = useRace();
  const {
    user: { permissions },
  } = useUser();
  const { edition } = useEdition();

  const [search, setSearch] = useState("");
  const {
    data: { chips },
  } = useSWR(
    `/events/${edition.event.id}/editions/${edition.id}/chips?` +
      `${race.race ? "race=" + race.race : ""}`,

    {
      suspense: true,
    }
  );

  const [modal, setModal] = useState(false);
  const [selectedChip, setSelectedChip] = useState(null);

  const handleRowClick = (data: any) => {
    setSelectedChip(data);
    setModal(true);
  };

  return (
    <Card>
      <Card.Section
        p="md"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Text weight={500} size="md">
          Listado de chips
        </Text>
        <Group position="right" mb="sm">
          <SettingButton />
        </Group>
      </Card.Section>
      {race.race != "" && (
        <Group>
          <Badge>
            {" "}
            Carrera: <b>{race.currentRace.name}</b>
          </Badge>
        </Group>
      )}

      <div className={classes.header}>
        {handleFunctionPermissions(["timing.config"], permissions) ? (
          <div>
            <NewChipButton />
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <TextInput
            width={"100%"}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Buscar chips"
            icon={<IconSearch />}
          />
        </div>
      </div>

      <ChipsTable data={chips} search={search} onClick={handleRowClick} />
      <ChipsModal
        opened={modal}
        setOpened={setModal}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
      />
    </Card>
  );
};

const SettingButton = () => {
  const [generateModal, setGenerateModal] = useState(false);
  return (
    <>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button variant="outline" size={"xs"}>
            <Settings size={18} />
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<DatabaseImport size={14} />}
            onClick={() => setGenerateModal(true)}
          >
            Generar chips
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <GenerateChipsModal opened={generateModal} setOpened={setGenerateModal} />
    </>
  );
};
export default EditionChips;
