import { Button, Group, Stepper } from "@mantine/core";
import { useState } from "react";
import BaseEditionComponent from "../../../../core/BaseEditionComponent";
import UploadRunnersFile from "./components/UploadFile";

const ImportRunners = () => {
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  return (
    <BaseEditionComponent>
      <>
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Subir corredores">
            <UploadRunnersFile />
          </Stepper.Step>
          <Stepper.Step label="Selecciona los campos">
            Step 2 content: Verify email
          </Stepper.Step>
          <Stepper.Completed>
            Completed, click back button to get to previous step
          </Stepper.Completed>
        </Stepper>

        <Group position="center" mt="xl">
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
          <Button onClick={nextStep}>Next step</Button>
        </Group>
      </>
    </BaseEditionComponent>
  );
};

export default ImportRunners;
