import { Avatar, Group, Text, Anchor } from "@mantine/core";
import BaseTable from "../../../../core/BaseTable";
const columns = [
  {
    Header: "Organizador",
    Cell: ({ row }: any) => (
      <Group spacing="sm">
        <Avatar size={30} src={row.original.logo} radius={30} />
        <Text size="sm" weight={500}>
          {row.original.name}
        </Text>
      </Group>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }: any) => (
      <Anchor<"a">
        size="sm"
        href="#"
        onClick={(event) => event.preventDefault()}
      >
        {row.original.email}
      </Anchor>
    ),
  },

  {
    Header: "Teléfono",
    accessor: "phone",
  },
  {
    Header: "Sitio web",
    accessor: "web",
  },
];

export function EventsManagersTable(props: any) {
  return <BaseTable columns={columns} {...props} />;
}

export default EventsManagersTable;
