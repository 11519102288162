import { FC, useEffect } from "react";
import { Modal, Button, Group, TextInput, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker, TimeInput } from "@mantine/dates";
import dayjs from "dayjs";
import api from "../../../../../api";
import useEdition from "../../../../../hooks/useEdition";
import { showSuccessToast } from "../../../../../utils/toasts";
import { mutate } from "swr";

interface Props {
  opened: boolean;
  setOpened: (value: boolean) => void;
  race?: any;
}
const RacesModal: FC<Props> = (props: Props) => {
  const { opened, setOpened, race } = props;
  const { edition } = useEdition();

  const form = useForm({
    initialValues: {
      name: "",
      distance: 0,
      public: true,
      started_at: "",
    },
  });

  const create = (values: any) => {
    const time = dayjs(values.started_at_time).format("HH:mm");
    const date = dayjs(values.started_at_date).format("YYYY-MM-DD");
    const sendData = {
      ...values,
      started_at: dayjs(date + " " + time).format("YYYY-MM-DD HH:mm:ss"),
    };

    api
      .post(
        `/events/${edition.event.id}/editions/${edition.id}/races`,
        sendData
      )
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Carrera creada correctamente" });
          form.reset();
          mutate(`/events/${edition.event.id}/editions/${edition.id}/races`);
          setOpened(false);
        }
      });
  };

  const update = (values: any) => {
    const time = dayjs(values.started_at_time).format("HH:mm");
    const date = dayjs(values.started_at).format("YYYY-MM-DD");

    console.log(time, date, values.started_at_date);
    const sendData = {
      ...values,
      started_at: dayjs(date + " " + time).format("YYYY-MM-DD HH:mm:ss"),
    };

    api
      .put(
        `/events/${edition.event.id}/editions/${edition.id}/races/${race.id}`,
        sendData
      )
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Carrera actualizada correctamente" });
          form.reset();
          mutate(`/events/${edition.event.id}/editions/${edition.id}/races`);
          setOpened(false);
        }
      });
  };

  useEffect(() => {
    if (opened && race) {
      form.setValues({
        ...race,
        started_at: dayjs(race.started_at).toDate(),
        started_at_time: dayjs(race.started_at).toDate(),
      });
    }
    if (!opened) {
      form.reset();
    }
  }, [opened]);
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={race ? "Actualizar carrera" : "Crear nueva carrera"}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            if (race) {
              update(values);
            } else {
              create(values);
            }
          })}
        >
          <TextInput
            label="Nombre"
            name="name"
            required
            {...form.getInputProps("name")}
          />
          <TextInput
            type="number"
            label="Distancia"
            name="distance"
            required
            rightSection={<>m</>}
            mt={"sm"}
            {...form.getInputProps("distance")}
          />

          <DatePicker
            label="Fecha de inicio"
            mt={"sm"}
            name="started_at"
            required
            {...form.getInputProps("started_at")}
          />
          <TimeInput
            label="Hora de inicio"
            mt={"sm"}
            name="started_at_time"
            required
            {...form.getInputProps("started_at_time")}
          />

          <Switch
            label="Carrera pública"
            mt={"md"}
            checked={form.values.public}
            name="public"
            {...form.getInputProps("public")}
          />

          <Button mt="sm" fullWidth={true} type="submit">
            {race ? "Actualizar carrera" : "Crear carrera"}
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default RacesModal;
