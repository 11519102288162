import BaseTable from "../../../../../core/BaseTable";
import dayjs from "dayjs";
import { Check, X } from "tabler-icons-react";

const columns = [
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Distancia",
    accessor: "distance",
    Cell: ({ value }: any) => <>{value} m</>,
  },

  {
    Header: "Fecha de inicio",
    accessor: "started_at",
    Cell: ({ value }: any) => <>{dayjs(value).format("DD/MM/YYYY HH:mm")}</>,
  },
  {
    Header: "Pública",
    accessor: "public",
    Cell: ({ value }: any) => (
      <>{value ? <Check size={20} /> : <X size={20} />}</>
    ),
  },
];

export function RacesTable(props: any) {
  return <BaseTable columns={columns} {...props} />;
}

export default RacesTable;
