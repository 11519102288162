import { Button, FileInput, Image, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { IconUpload } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import api from "../../../../api";
import { handleResponseError } from "../../../../utils/error";
import { showSuccessToast } from "../../../../utils/toasts";
import styles from "./styles";

interface Props {
  eventManager?: any;
}
const EventsManagersForm: React.FC<Props> = (props: Props) => {
  const { eventManager } = props;
  const navigate = useNavigate();

  const { classes } = styles();

  const generateInitialValues = () => {
    if (eventManager) {
      return {
        name: eventManager.name,
        email: eventManager.email,
        phone: eventManager.phone,
        logo: null,
      };
    } else {
      return {
        name: "",
        email: "",
        phone: "",
        logo: "",
      };
    }
  };

  const form = useForm({
    initialValues: generateInitialValues(),
    validate: yupResolver(validationSchema),
  });

  const createEventManager = (values: any) => {
    var formData = new FormData();
    formData.append("logo", values.logo);
    api
      .post(
        "/event-managers",
        { ...values, ...formData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.ok) {
          showSuccessToast({
            message: "Organizador creado correctamente",
          });
          navigate("/event-managers");
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  const updateEventManager = (values: any) => {
    var formData = new FormData();
    if (values.logo) {
      formData.append("logo", values.logo);
    }
    api
      .post(
        `/event-managers/${eventManager.id}`,
        { ...values, ...formData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.ok) {
          showSuccessToast({
            message: "Organizador actualizado correctamente",
          });
          navigate("/event-managers");
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  return (
    <div>
      <form
        onSubmit={form.onSubmit((values) => {
          if (eventManager) {
            updateEventManager(values);
          } else {
            createEventManager(values);
          }
        })}
      >
        <TextInput
          label="Nombre del organizador"
          required
          name="name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Correo electrónico"
          required
          mt={"sm"}
          name="email"
          {...form.getInputProps("email")}
        />
        <TextInput
          label="Teléfono"
          mt={"sm"}
          name="phone"
          {...form.getInputProps("phone")}
        />
        <TextInput label="Sitio web" mt={"sm"} />

        <FileInput
          label="Logotipo"
          placeholder="Haga click para subir el logotipo"
          name="logo"
          mt={"sm"}
          icon={<IconUpload size={14} />}
          accept="image/png"
          {...form.getInputProps("logo")}
          type="button"
        />
        {eventManager && !form.values.logo && (
          <div className={classes.previewContainer}>
            <Image src={eventManager.logo} className={classes.preview} />
          </div>
        )}
        <Button type="submit" fullWidth mt="sm">
          {eventManager ? "Actualizar organizador" : "Crear organizador"}
        </Button>
      </form>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  email: Yup.string()
    .email("El correo electrónico no es valido")
    .required("El correo electrónico es requerido"),
  phone: Yup.string().nullable(),
  web: Yup.string().nullable(),
  logo: Yup.mixed(),
});

export default EventsManagersForm;
