import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  previewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },
  preview: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {},

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      width: "200px",
      height: "auto",
    },
  },
}));

export default useStyles;
