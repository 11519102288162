import { Box, Header, Image, Menu, Select } from "@mantine/core";
import { Menu2 } from "tabler-icons-react";
import useCompany from "../../../../hooks/useCompany";
import ProfileMenu from "../../MainLayout/MainHeader/components/ProfileMenu";
import RaceSelect from "./components/RaceSelect";
import styles from "./styles";

interface EventHeaderProps {
  setOpen: (value: boolean) => void;
  open: boolean;
  isEvent?: boolean;
}

const EventHeader: React.FC<EventHeaderProps> = (props: EventHeaderProps) => {
  const { classes, cx } = styles();
  const { setOpen, open, isEvent } = props;

  const {
    company: { logo },
  } = useCompany();

  return (
    <Header height={"50px"} className={classes.header}>
      <Box ml="sm" className={classes.leftSection}>
        <Box onClick={() => setOpen(!open)} className={classes.openMenu}>
          <Menu2 size={16} />
        </Box>

        <Image src={logo} className={classes.logo} />
      </Box>
      <Box className={classes.rightSection}>
        {!isEvent && <RaceSelect />}
        <ProfileMenu classes={classes} />
      </Box>
    </Header>
  );
};

export default EventHeader;
