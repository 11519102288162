import { Navbar, ScrollArea, Tooltip, UnstyledButton } from "@mantine/core";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useEdition from "../../../../hooks/useEdition";
import useUser from "../../../../hooks/useUser";
import routes from "../../../../routes/mainLayout";
import styles from "../styles";

const linksMockdata = [
  { name: "Dashboard", route: "/" },
  { name: "Cronometraje", route: "/timing" },
  { name: "Corredores", route: "/runners" },
  { name: "Categorias", route: "/categories" },
  { name: "Clubes", route: "/clubs" },
  { name: "Chips", route: "/chips" },
];

const EditionSidebar = (props: any) => {
  const { classes, cx } = styles();
  const [activeLink, setActiveLink] = useState("Settings");

  const { edition } = useEdition();

  const { open, isEvent = false } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    user: { permissions },
  } = useUser();

  const mainLinks = routes.map((link) => {
    const checkPermissions = [...link.permissions, "superadmin", "admin"];
    const wilcard = link.permissions.map(
      (permission) => permission.split(".")[0] + ".*"
    );
    const finalPermissions = [...checkPermissions, ...wilcard];

    if (
      finalPermissions.some((permission) => permissions.includes(permission)) &&
      link.visible
    ) {
      return (
        <Tooltip
          label={link.label}
          position="right"
          withArrow
          transitionDuration={0}
          key={link.label}
        >
          <UnstyledButton
            onClick={() => navigate(link.route)}
            className={cx(classes.mainLink, {
              [classes.mainLinkActive]:
                link.route === "/"
                  ? link.route === pathname
                  : link.route === pathname || pathname.startsWith(link.route),
            })}
          >
            {link.icon && <link.icon />}
          </UnstyledButton>
        </Tooltip>
      );
    }
  });

  const links = () => {
    const editionid = edition.id;
    return linksMockdata.map((link) => (
      <a
        className={cx(classes.link, {
          [classes.linkActive]:
            pathname ===
              "/editions/" + edition.event.id + "/" + editionid + link.route ||
            (link.route != "/" &&
              pathname.startsWith(
                "/editions/" + edition.event.id + "/" + editionid + link.route
              )),
        })}
        onClick={(event) => {
          event.preventDefault();
          navigate(
            "/editions/" + edition.event.id + "/" + editionid + link.route
          );
        }}
        key={link.name}
      >
        {link.name}
      </a>
    ));
  };

  return (
    <>
      <Navbar
        height="calc(100% - 50px)"
        width={{ sm: 300 }}
        mt="50px"
        hiddenBreakpoint={"sm"}
        hidden={open}
      >
        <Navbar.Section grow className={classes.wrapper}>
          <div className={classes.aside}>{mainLinks}</div>
          <div className={classes.main}>
            <ScrollArea style={{ height: "100vh" }}>{links()}</ScrollArea>
          </div>
        </Navbar.Section>
      </Navbar>
    </>
  );
};

export default EditionSidebar;
