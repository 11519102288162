import BaseTable from "../../../../../core/BaseTable";

const columns = [
  {
    Header: "Dorsal",
    accessor: "number",
  },
  {
    Header: "Nombre y apellidos",
    accessor: "name",
  },
  {
    Header: "Club",
    accessor: "club",
  },

  {
    Header: "Categoría",
    accessor: "category",
  },
  {
    Header: "DNI",
    accessor: "document",
  },
];

export function RunnersTable(props: any) {
  return <BaseTable columns={columns} {...props} />;
}

export default RunnersTable;
