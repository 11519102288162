export const getCompany = () => {
  const data = localStorage.getItem("company_data");

  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
};

export const setCompany = (company: any) => {
  localStorage.setItem("company_data", JSON.stringify(company));
};
