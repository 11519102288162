import { IconCalendar, IconHome2, IconUsers } from "@tabler/icons";
import App from "../App";
import EventEdition from "../pages/EventEdition";
import EditionRunners from "../pages/EventEdition/EditionRunners";
import ImportRunners from "../pages/EventEdition/EditionRunners/ImportRunners";
import EventManagers from "../pages/EventManagers";
import CreateEventManager from "../pages/EventManagers/CreateEventManager";
import UpdateEventManager from "../pages/EventManagers/UpdateEventManager";
import EventsList from "../pages/EventsList";
import CreateEdition from "../pages/EventsList/CreateEdition";
import CreateEvent from "../pages/EventsList/CreateEvent";
import ShowEvent from "../pages/EventsList/ShowEvent";
import EditionTimingPage from "../pages/EventEdition/EditionTiming/EditionTiming";
import EditionCategories from "../pages/EventEdition/EditionCategories";
import EditionClubs from "../pages/EventEdition/EditionClubs";
import EditionChips from "../pages/EventEdition/EditionChips";

const mainLayoutRoutes = [
  {
    icon: IconHome2,
    label: "Home",
    route: "/",
    permissions: ["timing.panel"],
    component: App,
    visible: true,
  },
  {
    icon: IconCalendar,
    label: "Eventos",
    route: "/events",
    permissions: ["timing.panel"],
    component: EventsList,
    visible: true,
  },
  {
    route: "/events/create",
    permissions: ["events.create"],
    component: CreateEvent,
    visible: false,
  },
  {
    route: "/events/:id",
    permissions: ["events.edit"],
    component: ShowEvent,
    visible: false,
  },
  {
    route: "/events/:id/newEdition",
    permissions: ["events.edit"],
    component: CreateEdition,
    visible: false,
  },
  {
    route: "/editions/:eid/:id",
    permissions: ["timing.panel"],
    component: EventEdition,
    visible: false,
  },
  {
    route: "/editions/:eid/:id/runners",
    permissions: ["events.runners.show"],
    component: EditionRunners,
    visible: false,
  },
  {
    route: "/editions/:eid/:id/runners/import",
    permissions: ["events.edit"],
    component: ImportRunners,
    visible: false,
  },
  {
    route: "/editions/:eid/:id/timing",
    permissions: ["timing.panel"],
    component: EditionTimingPage,
    visible: false,
  },
  {
    route: "/editions/:eid/:id/categories",
    permissions: ["timing.panel"],
    component: EditionCategories,
    visible: false,
  },
  {
    route: "/editions/:eid/:id/clubs",
    permissions: ["timing.panel"],
    component: EditionClubs,
    visible: false,
  },

  {
    route: "/editions/:eid/:id/chips",
    permissions: ["timing.panel"],
    component: EditionChips,
    visible: false,
  },
  {
    icon: IconUsers,
    label: "Organizadores",
    route: "/event-managers",
    permissions: ["event-managers.create", "event-managers.edit"],
    component: EventManagers,
    visible: true,
  },
  {
    route: "/event-managers/create",
    permissions: ["event-managers.create"],
    component: CreateEventManager,
    visible: false,
  },
  {
    route: "/event-managers/:id",
    permissions: ["event-managers.edit"],
    component: UpdateEventManager,
    visible: false,
  },
];

export default mainLayoutRoutes;
