import { Center, Image, Text } from "@mantine/core";
import NotFounfIllustration from "../../assets/error/404.png";
import styles from "./styles";

const CompanyNotFound = () => {
  const { classes } = styles();
  return (
    <div>
      <Center
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <img className={classes.image} src={NotFounfIllustration} />
        <Text color={"#407bff"} size="xl" weight={"bolder"}>
          Empresa no encontrada
        </Text>

        <Text color={"#407bff"} size="md" align="center">
          No se ha podido encontrar ninguna empresa registrada con el dominio
          indicado
        </Text>
      </Center>
    </div>
  );
};

export default CompanyNotFound;
