import { Badge, Text } from "@mantine/core";
import dayjs from "dayjs";
import BaseTable from "../../../../../core/BaseTable";

const columns = [
  {
    Header: "Dorsal",
    accessor: "number",
  },
  {
    Header: "Chip",
    accessor: "chip",
  },
  {
    Header: "Chip propio",
    accessor: "own",
    Cell: ({ value }: any) => {
      return (
        <Badge color={value ? "green" : "yellow"}>{value ? "Sí" : "No"}</Badge>
      );
    },
  },
  {
    Header: "Chip devuelto",
    accessor: "returned_at",
    Cell: ({ cell: { value } }: any) => {
      if (value) {
        return <Text>{dayjs(value).format("DD/MM/YYYY HH:mm:ss")}</Text>;
      }
    },
  },
];

export function ChipsTable(props: any) {
  return <BaseTable columns={columns} {...props} />;
}

export default ChipsTable;
