import { Select } from "@mantine/core";
import { FC } from "react";
import useSWR from "swr";

interface Props {
  form: any;
  inputName: string;
}

const SelectManager: FC<Props> = (props: Props) => {
  const { form, inputName } = props;

  const {
    data: { eventManagers },
  } = useSWR("/event-managers", { suspense: true });
  if (eventManagers) {
    return (
      <Select
        label="Organizador de la edición"
        placeholder="Selecciona un organizador"
        mt="sm"
        required
        searchable
        name={inputName}
        {...form.getInputProps(inputName)}
        data={eventManagers.map((manager: any) => ({
          value: manager.id,
          label: manager.name,
        }))}
      />
    );
  }

  return <></>;
};

export default SelectManager;
