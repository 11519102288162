import { Card, Text } from "@mantine/core";
import MainLayout from "../../../layouts/MainLayout";
import EventsManagersForm from "../components/EventsManagersForm";

const CreateEventManager = () => {
  return (
    <MainLayout>
      <Card>
        <Card.Section p="md">
          <Text weight={500} size="md">
            Crear nuevo organizador
          </Text>
        </Card.Section>

        <Card.Section p="md">
          <EventsManagersForm />
        </Card.Section>
      </Card>
    </MainLayout>
  );
};

export default CreateEventManager;
