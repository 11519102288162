import { FC, useState } from "react";
import { Drawer, Button, Group, Radio, Alert } from "@mantine/core";
import { X } from "tabler-icons-react";
import useRace from "../../../../../hooks/useRace";
import api from "../../../../../api";
import useEdition from "../../../../../hooks/useEdition";
import {
  showLoadingToast,
  updateLoadingToast,
} from "../../../../../utils/toasts";
import { IconCheck } from "@tabler/icons";
import { mutate } from "swr";

interface Props {
  opened: boolean;
  setOpened: (value: boolean) => void;
}

const CategoriesConfig: FC<Props> = (props: Props) => {
  const { opened, setOpened } = props;
  const race: any = useRace();
  const { edition } = useEdition();

  console.log(race);
  const { categories_calculation, id } = race.currentRace;
  const [value, setValue] = useState(categories_calculation);

  const update = () => {
    showLoadingToast({
      id: "reorder-categories",
      title: "Recalculando categorías",
      message: "Esto puede tardar unos segundos",
    });
    setOpened(false);
    api
      .post(
        `/events/${edition.event.id}/editions/${edition.id}/races/${id}/categories/config`,
        { categories_calculation: value }
      )
      .then((data) => {
        if (data.data.ok) {
          updateLoadingToast({
            id: "reorder-categories",
            title: "Recalculando categorías",
            message: "Categorías recalculadas correctamente",
            icon: <IconCheck size={16} />,
            color: "teal",
          });
          mutate(
            `/events/${edition.event.id}/editions/${edition.id}/races/${id}/categories`
          );
          mutate(`/events/${edition.event.id}/editions/${edition.id}/races`);
        }
      })
      .catch((err) => {
        updateLoadingToast({
          id: "reorder-categories",
          title: "Recalculando categorías",
          message: "Error al recalcular las categorias",
          color: "red",
          icon: <X size={16} />,
        });
      });
  };

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Configuración de categorias"
        padding="xl"
        position="right"
        size="lg"
      >
        <Radio.Group
          orientation="vertical"
          label="Calcular categorias en base a el"
          value={value}
          onChange={(e) => setValue(e)}
          required
        >
          <Radio value="current_year" label="Año actual" />
          <Radio value="event_date" label="Día del evento" />
        </Radio.Group>

        <Alert color={"yellow"} mt="md">
          Cambiar este ajuste, provocará que las fechas de las categorias se
          recalculen
        </Alert>
        <Group position="center" mt="md">
          <Button fullWidth onClick={update}>
            Guardar configuración
          </Button>
        </Group>
      </Drawer>
    </>
  );
};

export default CategoriesConfig;
