import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  main: {
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      padding: theme.spacing.md,
      height: "100vh - 60px",
      marginTop: "50px",
    },

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginTop: "70px",
      marginLeft: "-60px", // -60px: sidebar width

      minHeight: "90.5vh",
    },
  },
}));

export default useStyles;
