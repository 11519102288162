import { Card, Text } from "@mantine/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import useSWRInmutable from "swr/immutable";
import SWRrender from "../../../core/SWRrender";
import MainLayout from "../../../layouts/MainLayout";
import EventsManagersForm from "../components/EventsManagersForm";

const UpdateEventManager = () => {
  const { id } = useParams();

  return (
    <MainLayout>
      <SWRrender>
        <RenderUpdateForm id={id} />
      </SWRrender>
    </MainLayout>
  );
};

const RenderUpdateForm = ({ id }: any) => {
  const {
    data: { eventManagers },
  } = useSWRInmutable("/event-managers/" + id, { suspense: true });

  useEffect(() => {
    mutate("/event-managers/" + id);
  }, []);

  return (
    <Card>
      <Card.Section p="md">
        <Text weight={500} size="md">
          Actualizar organizador
        </Text>
      </Card.Section>

      <Card.Section p="md">
        <EventsManagersForm eventManager={eventManagers} />
      </Card.Section>
    </Card>
  );
};

export default UpdateEventManager;
