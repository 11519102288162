import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },

  aside: {
    flex: "0 0 60px",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.cyan[9]
        : theme.colors.cyan[7],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "10px",
    height: "100%",
  },

  main: {
    flex: 1,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.cyan[8]
        : theme.colors.cyan[8],
  },

  mainLink: {
    width: 44,
    height: 44,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
    marginBottom: "3px",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[0]
        : theme.colors.gray[0],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.cyan[5]
          : theme.colors.cyan[5],
    },
  },

  mainLinkActive: {
    "&, &:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.cyan[7]
          : theme.colors.cyan[5],
      color: theme.colors.gray[0],
    },
  },
}));

export default useStyles;
