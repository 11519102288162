import { Alert, Button, Card, Paper, Text } from "@mantine/core";
import { IconAlertCircle, IconPlus } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import SWRrender from "../../../../../core/SWRrender";
import useEdition from "../../../../../hooks/useEdition";
import useUser from "../../../../../hooks/useUser";
import { handleFunctionPermissions } from "../../../../../utils/permissions";
import RacesModal from "../RacesModal";
import RacesTable from "../RacesTable";

const ManageRaces = () => {
  return (
    <>
      <Alert icon={<IconAlertCircle size={16} />} color="blue" mb={"sm"}>
        Selecciona una carrera arriba para gestionar el cronometraje de la
        misma.
      </Alert>
      <Card>
        <Card.Section p="md">
          <Text weight={500} size="md">
            Listado de carreras
          </Text>
        </Card.Section>

        <SWRrender>
          <RacesList />
        </SWRrender>
      </Card>
    </>
  );
};

const RacesList = () => {
  const { edition } = useEdition();
  const [showModal, setShowModal] = useState(false);
  const [selectedRace, setSelectedRace] = useState(null);

  const {
    user: { permissions },
  } = useUser();
  const {
    data: { races },
  } = useSWR(
    "/events/" + edition.event.id + "/editions/" + edition.id + "/races",
    { suspense: true }
  );

  const raceClick = (value: any) => {
    if (handleFunctionPermissions(["timing.config"], permissions)) {
      setSelectedRace(value);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (!showModal) {
      setSelectedRace(null);
    }
  }, [showModal]);
  return (
    <div>
      {handleFunctionPermissions(["timing.config"], permissions) && (
        <Card.Section p="md">
          <div>
            <Button onClick={() => setShowModal(true)}>Nueva carrera</Button>
          </div>
        </Card.Section>
      )}
      <RacesTable data={races} onClick={raceClick} />
      <RacesModal
        opened={showModal}
        setOpened={setShowModal}
        race={selectedRace}
      />
    </div>
  );
};

export default ManageRaces;
