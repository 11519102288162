import useSWR from "swr";
import { setCompany } from "../utils/storeCompany";

const useEventEditions = () => {
  const { pathname } = window.location;

  const event_id = pathname.split("/")[2];

  const { data, error } = useSWR(`/events/${event_id}/editions`, {
    suspense: true,
  });

  return {
    editions: data?.editions,
    eventId: event_id,
    error,
    isLoading: !data && !error,
  };
};

export default useEventEditions;
