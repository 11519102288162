export const handleFunctionPermissions = (
  requiredPermissions: string[],
  userPermissions: string[]
): boolean => {
  const checkPermissions = [...requiredPermissions, "superadmin", "admin"];
  const wilcard = requiredPermissions.map(
    (permission) => permission.split(".")[0] + ".*"
  );
  const finalPermissions = [...checkPermissions, ...wilcard];

  if (
    finalPermissions.some((permission) => userPermissions.includes(permission))
  ) {
    return true;
  }

  return false;
};
