import { Box, Header, Image } from "@mantine/core";
import { Menu2 } from "tabler-icons-react";
import useCompany from "../../../../hooks/useCompany";
import ProfileMenu from "./components/ProfileMenu";
import styles from "./styles";

interface MainHeaderProps {
  setOpen: (value: boolean) => void;
  open: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = (props: MainHeaderProps) => {
  const { classes, cx } = styles();
  const { setOpen, open } = props;

  const {
    company: { logo },
  } = useCompany();

  return (
    <Header height={"50px"} className={classes.header}>
      <Box ml="sm" className={classes.leftSection}>
        <Box onClick={() => setOpen(!open)} className={classes.openMenu}>
          <Menu2 size={16} />
        </Box>

        <Image src={logo} className={classes.logo} />
      </Box>
      <Box>
        <ProfileMenu classes={classes} />
      </Box>
    </Header>
  );
};

export default MainHeader;
