import { Card, Text } from "@mantine/core";
import SWRrender from "../../../core/SWRrender";
import EventLayout from "../../../layouts/EventLayout";
import CreateUpdateEditionForm from "../components/CreateUpdateEditionForm";

const CreateEdition = () => {
  return (
    <EventLayout isEvent={true}>
      <Card>
        <Card.Section p="md">
          <Text weight={500} size="md">
            Crear edición
          </Text>
        </Card.Section>

        <Card.Section p="md">
          <SWRrender>
            <CreateUpdateEditionForm />
          </SWRrender>
        </Card.Section>
      </Card>
    </EventLayout>
  );
};

export default CreateEdition;
