import { Box, Navbar, Tooltip, UnstyledButton } from "@mantine/core";
import { Suspense, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { Plus } from "tabler-icons-react";
import useEvent from "../../../../hooks/useEventEditions";
import useUser from "../../../../hooks/useUser";
import routes from "../../../../routes/mainLayout";
import styles from "../styles";

const linksMockdata = [
  "Security",
  "Settings",
  "Dashboard",
  "Releases",
  "Account",
  "Orders",
  "Clients",
  "Databases",
  "Pull Requests",
  "Open Issues",
  "Wiki pages",
];

const EventSidebar = (props: any) => {
  const { classes, cx } = styles();
  const [activeLink, setActiveLink] = useState("Settings");

  const { open } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    user: { permissions },
  } = useUser();

  const mainLinks = routes.map((link) => {
    const checkPermissions = [...link.permissions, "superadmin", "admin"];
    const wilcard = link.permissions.map(
      (permission) => permission.split(".")[0] + ".*"
    );
    const finalPermissions = [...checkPermissions, ...wilcard];

    if (
      finalPermissions.some((permission) => permissions.includes(permission)) &&
      link.visible
    ) {
      return (
        <Tooltip
          label={link.label}
          position="right"
          withArrow
          transitionDuration={0}
          key={link.label}
        >
          <UnstyledButton
            onClick={() => navigate(link.route)}
            className={cx(classes.mainLink, {
              [classes.mainLinkActive]:
                link.route === "/"
                  ? link.route === pathname
                  : link.route === pathname || pathname.startsWith(link.route),
            })}
          >
            {link.icon && <link.icon />}
          </UnstyledButton>
        </Tooltip>
      );
    }
  });

  return (
    <>
      <Navbar
        height="calc(100% - 50px)"
        width={{ sm: 300 }}
        mt="50px"
        hiddenBreakpoint={"sm"}
        hidden={open}
      >
        <Navbar.Section grow className={classes.wrapper}>
          <div className={classes.aside}>{mainLinks}</div>
          <Suspense>
            <div className={classes.main}>
              <RenderEditionsBar
                activeLink={activeLink}
                setActiveLink={setActiveLink}
              />
            </div>
          </Suspense>
        </Navbar.Section>
      </Navbar>
    </>
  );
};

export default EventSidebar;

const RenderEditionsBar = ({ activeLink, setActiveLink }: any) => {
  const { editions, eventId } = useEvent();
  const { classes, cx } = styles();
  const navigate = useNavigate();

  const links = () => {
    if (editions) {
      const linksEditions = [];

      linksEditions.push(
        <a
          className={cx(classes.link, {
            [classes.linkActive]: activeLink === "new",
          })}
          href="/"
          onClick={(event) => {
            event.preventDefault();
            navigate("/events/" + eventId + "/newEdition");
            setActiveLink("new");
          }}
          key={"a"}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Plus style={{ marginRight: "5px" }} />
            Nueva edicion
          </Box>
        </a>
      );

      linksEditions.push(
        editions.map((edition: any) => (
          <a
            className={cx(classes.link, {
              [classes.linkActive]: activeLink === edition,
            })}
            href="/"
            onClick={(event) => {
              event.preventDefault();
              //setActiveLink(edition);
              navigate("/editions/" + eventId + "/" + edition.id);
            }}
            key={edition}
          >
            {edition.name}
          </a>
        ))
      );

      return linksEditions;
    } else {
      return [];
    }
  };

  return <>{links()}</>;
};
