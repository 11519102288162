import { Button, Pagination, TextInput } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import EventCard from "../components/EventCard";
import styles from "./styles";

const AllEvents = () => {
  const {
    data: { events },
  } = useSWR("/events", { suspense: true });
  const { classes } = styles();
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);

  const [filteredData, setFilteredData] = useState(events);

  useEffect(() => {
    if (search) {
      const filtered = events.filter((event: any) =>
        event?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setPage(1);
      setFilteredData(filtered);
    } else {
      setPage(1);

      setFilteredData(events);
    }
  }, [events, search]);

  const total = Math.round(filteredData.length / 10) + 1; // 10 events per page

  const navigate = useNavigate();

  const generateEvents = () => {
    const events = [];
    const start = (page - 1) * 10;
    const end = page * 10;
    for (let i = start; i < end; i++) {
      if (filteredData[i]) {
        events.push(<EventCard event={filteredData[i]} />);
      }
    }
    return events;
  };
  return (
    <div>
      <div className={classes.header}>
        <Button mr="sm" onClick={() => navigate("/events/create")}>
          <IconPlus />
        </Button>
        <TextInput
          onChange={(e) => setSearch(e.target.value)}
          className={classes.input}
          placeholder="Buscar eventos..."
        />
      </div>

      <div className={classes.eventContainer}>
        {/*filteredData.map((item: any) => (
          <EventCard event={item} />
        ))*/}
        {generateEvents()}
      </div>

      <div className={classes.pagination}>
        <Pagination total={total} page={page} onChange={setPage} />
      </div>
    </div>
  );
};

export default AllEvents;
