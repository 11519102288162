import { Button, Popover, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { IconPlus } from "@tabler/icons";
import { useState } from "react";
import { mutate } from "swr";
import * as Yup from "yup";
import api from "../../../../../api";
import useEdition from "../../../../../hooks/useEdition";
import useRace from "../../../../../hooks/useRace";
import { handleResponseError } from "../../../../../utils/error";
import { showSuccessToast } from "../../../../../utils/toasts";

const NewChipButton = () => {
  const [opened, setOpened] = useState(false);

  const { edition } = useEdition();

  const race: any = useRace();

  const form = useForm({
    initialValues: {
      number: "",
      chip: "",
    },
    validate: yupResolver(validationSchema),
  });
  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width="lg"
      trapFocus
      position="right-start"
      withArrow
      shadow="xl"
    >
      <Popover.Target>
        <Button onClick={() => setOpened(!opened)}>
          <IconPlus />
        </Button>
      </Popover.Target>
      <Popover.Dropdown
        sx={(theme) => ({
          background:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        })}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            api
              .post(
                `/events/${edition.event.id}/editions/${edition.id}/chips`,
                values
              )
              .then((data) => {
                if (data.data.ok) {
                  showSuccessToast({ message: "Chip creado correctamente" });
                  mutate(
                    `/events/${edition.event.id}/editions/${edition.id}/chips?` +
                      `${race.race ? "race=" + race.race : ""}`
                  );
                  setOpened(false);
                  form.reset();
                }
              })
              .catch((err) => {
                handleResponseError(err);
              });
          })}
        >
          <TextInput
            label="Dorsal"
            placeholder="Dorsal"
            name="number"
            size="xs"
            {...form.getInputProps("number")}
          />
          <TextInput
            label="Código de chip"
            placeholder="Código de chip"
            name="chip"
            size="xs"
            mt="xs"
            {...form.getInputProps("chip")}
          />
          <Button mt="xs" type="submit" fullWidth size="xs">
            Crear chip
          </Button>
        </form>
      </Popover.Dropdown>
    </Popover>
  );
};

const validationSchema = Yup.object().shape({
  number: Yup.string().required("Dorsal es requerido"),
  chip: Yup.string().required("Código de chip es requerido"),
});

export default NewChipButton;
