import { Navbar, UnstyledButton, Tooltip } from "@mantine/core";
import { Home2, Calendar } from "tabler-icons-react";
import styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { IconUsers } from "@tabler/icons";
import useUser from "../../../../hooks/useUser";
import routes from "../../../../routes/mainLayout";

interface MainSidebarProps {
  open: boolean;
}

const MainSidebar: React.FC<MainSidebarProps> = (props: MainSidebarProps) => {
  const { classes, cx } = styles();

  const navigate = useNavigate();

  const { open } = props;

  const { pathname } = useLocation();

  const {
    user: { permissions },
  } = useUser();

  const mainLinks = routes.map((link) => {
    const checkPermissions = [...link.permissions, "superadmin", "admin"];
    const wilcard = link.permissions.map(
      (permission) => permission.split(".")[0] + ".*"
    );
    const finalPermissions = [...checkPermissions, ...wilcard];

    if (
      finalPermissions.some((permission) => permissions.includes(permission)) &&
      link.visible
    ) {
      return (
        <Tooltip
          label={link.label}
          position="right"
          withArrow
          transitionDuration={0}
          key={link.label}
        >
          <UnstyledButton
            onClick={() => navigate(link.route)}
            className={cx(classes.mainLink, {
              [classes.mainLinkActive]:
                link.route === "/"
                  ? link.route === pathname
                  : link.route === pathname || pathname.startsWith(link.route),
            })}
          >
            {link.icon && <link.icon />}
          </UnstyledButton>
        </Tooltip>
      );
    }
  });

  return (
    <>
      <Navbar
        height="calc(100% - 50px)"
        width={{ base: 60 }}
        mt="50px"
        hiddenBreakpoint={"sm"}
        hidden={open}
      >
        <Navbar.Section grow className={classes.wrapper}>
          <div className={classes.aside}>{mainLinks}</div>
        </Navbar.Section>
      </Navbar>
    </>
  );
};

export default MainSidebar;
